import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';
import logo from '../../assets/logo.png';

const Menu = () => (
  <>
  <p><a href="#whpt3">Qui sommes-nous?</a></p>
  <p><a href="#features">Nos Activités</a></p>
  <p><a href="#blog">Blog</a></p>
  <p><a href="#footer">Contacts</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
      <div className='gpt3_navbar'>
          <div className='gpt3_navbar-links'>
              <div className='gpt3_navbar-links_logo'>
                <img src={logo} alt='logo' />
                <p>SIPROCOM<span>-TRANS</span></p>
              </div>
              <div className='gpt3_navbar-links_container'>
                <Menu />
              </div>
          </div>
          <div className='gpt3_navbar-menu'>
              { toggleMenu 
                ? <RiCloseLine color="#fff" size = {27} onClick = {() => setToggleMenu(false)} />
                : <RiMenu3Line color="#fff" size = {27} onClick= {() => setToggleMenu(true)} />
              }
              {toggleMenu && (
                  <div className='gpt3_navbar-menu_container scale-up-center'>
                  <div className='gpt3_navbar-menu_container-links'>
                        <Menu />
                  </div>
                </div>
              )}
          </div>
      </div>
  )
}

export default Navbar