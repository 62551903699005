import React from 'react';
import './cta.css';

const Cta = () => {
  return (
    <div className='gpt3_cta'>
      <div className='gpt3_cta-content'>
        <p>Vous êtes interessé par nos services ?</p>
        <h3> Contactez-nous !</h3>
      </div>
      <div className='gpt3_cta-btn'>
        <button type='button'><a href='tel:+228 91 83 66 00'>Nous Contacter</a></button>
      </div>
    </div>
  )
}

export default Cta