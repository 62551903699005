import React from 'react';
import './header.css';
import entreprise from '../../assets/accueil.jpeg'

const Header = () => {
  return (
    <div className='gpt3_header section_padding' id='home'>
      <div className='gpt3_header-content'>
          <h1 className='gradient_text'>
             Commissionnaire agrée en douanes
          </h1>
          <p>Le transitaire est par définition un professionnel du transport international dont l’objectif est d’assurer à ses clients les meilleurs coûts, délais et conditionnements des marchandises tout au long de leur transit, dans le respect des réglementations.
          </p>
          <div className='gpt3_header-content_input'>
            <button type='button' id='contacts'><a href='tel:+228 91 83 66 00'>Nous Contacter</a></button>
          </div>
      </div>
      <div className='gpt3_header-image'>
          <img src={entreprise} alt='entreprise' />
      </div>
    </div>
  )
}

export default Header