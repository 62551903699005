import React from 'react';
import './footer.css';
import logo from '../../assets/logo.png';

const Footer = () => {
  return (
    <div className='gpt3_footer section_padding' id='footer'>
      <div className='gpt3_footer-heading'>
        <h1 className='gradient_text'>
            Avec SIPROCOM-TRANS, votre satisfaction est assurée.
        </h1>
      </div>

      <div className='gpt3_footer-links'>
        <div className='gpt3_footer-links_logo'>
          <div className='gpt3_footer-links_logo-image'>
            <img src={logo} alt="logo" />
            <h2>SIPROCOM<span>-TRANS</span></h2>
          </div>
        </div>
        <div className='gpt3_footer-links_div'>
          <h4>Liens utiles</h4>
          <p><a href="#whpt3">Qui sommes nous ?</a></p>
        </div>
        <div className='gpt3_footer-links_div'>
          <h4>Nos services</h4>
          <p>Transit-douane</p>
          <p>Consignation</p>
          <p>Entrepôsage</p>
          <p>Commerce générale export-import</p>
        </div>
        <div className='gpt3_footer-links_div'>
          <h4>Nous contacter</h4>
          <p>Tel: 22 21 17 73</p>
          <p>Mobile: <a href='tel:+228 91 83 66 00'>+228 91 83 66 00</a></p>
          <p>Email: <a href='mailto:siprocomtrans@yahoo.com'>siprocomtrans@yahoo.com</a></p>
          <p>BP: 61528</p>
        </div>
      </div>
    </div>
  )
}

export default Footer